<template>
  <div>
    <div>
      <el-image fit="contain" :src="abouts" />
    </div>
    <el-breadcrumb separator="/" style="margin-left:10px; margin-top:10px">
      <el-breadcrumb-item :to="{ path: '/' }">
        {{ $t('Nav.home') }}
      </el-breadcrumb-item>
      <el-breadcrumb-item> {{ $t('Nav.itc') }} </el-breadcrumb-item>
    </el-breadcrumb>
    <div style="position: relative;clear: both; margin-top:20px">
      <div style="width:100%; margin:0 auto;">
        <div
          style="margin-top:30px;text-align:center; height: 24px;font-size: 20px;font-family: Noto Sans S Chinese;font-weight: bold;color: #475AFF;line-height: 17px;"
        >
          {{ $t('Nav.anlifenxiang') }}
        </div>
        <div style="text-align:left;margin-top:30px;margin-left:10px">
          <div>
            <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext01') }}
          </div>
          <div>
            <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext02') }}
          </div>
          <div>
            <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext03') }}
          </div>
          <div>
            <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext04') }}
          </div>
          <div>
            <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext05') }}
          </div>
          <div>
            <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext06') }}
          </div>
          <div>
            <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext07') }}
          </div>
          <div>
            <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext08') }}
          </div>
          <div>
            <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext09') }}
          </div>
          <div>
            <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext10') }}
          </div>
          <div>
            <span style="margin-right:5px">●</span>{{ $t('Nav.anlitext11') }}
          </div>
        </div>
        <div style="margin-top:20px;">
          <img :src="corporateVision3" alt="" style="width:100%" />
        </div>
      </div>
    </div>

    <div style="clear: both; overflow: hidden;margin-bottom:20px;"></div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Lazyload } from 'vant'
Vue.use(Lazyload)
export default {
  name: 'itc',
  components: {
    // HelloWorld
  },
  data() {
    return {
      abouts: require('../../assets/index/03.jpg'),
      ict: require('../../assets/mobile/07-img@3x.png'),
      point: require('../../assets/point.gif'),
      itc1: require('../../assets/itc/1.jpg'),
      itc2: require('../../assets/itc/5.jpg'),
      itc3: require('../../assets/itc/anli.png'),
      qygsImg1: require('../../assets/itc/case7/1.png'),
      qygsImg2: require('../../assets/itc/case7/2.png'),
      qygsImg3: require('../../assets/itc/case2/3.png'),
      corporateVision3: require('../../assets/itc/anli.png'),
    }
  },
  methods: {},
}
</script>

<style></style>
